<script>
export default {
  name: 'PhoneInput',
  data() {
    return {
      valid: null,
      prefilled: false,
    };
  },
  computed: {
    attrs() {
      const attrs = this.$attrs;
      attrs.mode = 'international';
      attrs.preferredCountries = ['GB', 'US', 'AU', 'NZ', 'CA'];
      attrs.dynamicPlaceholder = true;
      attrs.disabledFetchingCountry = !this.prefilled;

      if (!attrs.inputClasses) attrs.wrapperClasses = [];
      attrs.wrapperClasses.push('form-control');
      if (this.valid === true) attrs.wrapperClasses.push('is-valid');
      else if (this.valid === false) attrs.wrapperClasses.push('is-invalid');

      return attrs;
    },
  },
  methods: {
    onValidate(result) {
      this.valid = result.isValid;
    },
  },
  mounted() {
    if (this.$refs.input.value) {
      this.prefilled = true;
    }
  },
};
</script>

<template>
  <vue-tel-input ref='input'
                 v-bind="this.attrs"
                 v-on="this.$listeners"
                 @validate='onValidate'>
  </vue-tel-input>
</template>

<style>
  .vti__dropdown-list {
    padding: 0 !important;
  }

  .custom-control {
    z-index: unset;
  }
</style>
